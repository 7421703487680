import { Skeleton } from "~/components/ui/skeleton";
import { cn } from "~/lib/ui";

const Skeletons = ({
  size = 20,
  className,
}: {
  size?: number;
  className?: string;
}) => {
  const skeletons = new Array(size).fill(0).map((_, i) => (
    <div
      key={i}
      className={cn(
        "flex h-[400px] w-full p-4 no-underline hover:no-underline sm:py-5 md:w-1/3 lg:w-1/4",
        className,
      )}
    >
      <Skeleton className="h-full w-full" />
    </div>
  ));

  return <div className="flex flex-row flex-wrap">{skeletons}</div>;
};
const SkeletonsFilters = () => {
  return new Array(6)
    .fill(0)
    .map((_, i) => <Skeleton key={i} className="h-9 w-24" />);
};

const SkeletonsCategoryButtons = () => {
  const skeletons = new Array(6)
    .fill(0)
    .map((_, i) => <Skeleton key={i} className="h-[166px] w-[134px]" />);

  return (
    <div className="mx-6 mb-6 pt-2">
      <div className="bapcor-scrollbar gap-x-3 overflow-x-auto pb-[12px] pt-3 sm:flex lg:grid lg:grid-cols-8 lg:gap-y-3">
        {skeletons}
      </div>
    </div>
  );
};

export { Skeletons, SkeletonsCategoryButtons, SkeletonsFilters };
